import React from "react";

const AboutMe = () => {
  const text =
    "My name is Alen and I am a creative Front-end developer dedicated to building user-centred, high-impact, and quite simply good looking websites! I love problem solving and this is what initially drew me towards Front-end development.";

  const body = (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-center my-5">About Me</h1>
      <p className="mx-5 indent-5">{text}</p>
    </div>
  );

  return body;
};

export default AboutMe;
