import React from "react";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import Header from "./components/Header/Header";
import AboutMe from "./components/AboutMe/AboutMe";
import PreLoader from "./components/PreLoader/PreLoader";
import LetsConnect from "./components/LetsConnect/LetsConnect";
import MyWork from "./components/MyWork/MyWork";
// import OldPage from "./old/OldPage";

function scrollbarHandler() {
  document.body.style.overflowY = "auto";
}

window.onload = function () {
  setTimeout(scrollbarHandler, 2500);
};
function App() {
  return (
    <React.Fragment>
      <PreLoader />
      <NavBar />
      <Header />
      <AboutMe />
      <MyWork />
      <LetsConnect />
    </React.Fragment>
  );
}

export default App;
