import style from "./LetsConnect.module.css";
import LinkedInLogo from "../../old/pics/linkedinLogo.png";
import UpArrow from "../../old/pics/upArrow.svg";

const LetsConnect = () => {
  return (
    <>
      <a name="contactAnchor"></a>

      <div className={style.contactHero}>
        <div className="flex-container">
          <h1 className="my-5">Let's connect!</h1>
        </div>
        <div className="flex-container">
          <div className={style.social}>
            <p>Feel free to reach out and chat over virtual coffee</p>
            <a
              href="mailto:contact@alenbasic.ca"
              className="text-lg hover:brightness-75 duration-100"
            >
              contact@alenbasic.ca
            </a>
            <br />
            <a
              href="https://www.linkedin.com/in/alen-basic-964043206/"
              target="_blank"
              rel="noreferrer noopener"
              className="flex justify-center "
            >
              <img
                src={LinkedInLogo}
                alt="LinkedIn Logo"
                className="h-12 pt-2.5 hover:brightness-75 duration-100"
              />
            </a>
          </div>
        </div>
        <a
          href="#homeAnchor"
          className="flex justify-center items-center gap-2 text-lg hover:brightness-75 duration-100 pb-5"
        >
          Back to Top
          <img src={UpArrow} className="h-2.5" alt="Up arrow icon" />
        </a>
      </div>

      <footer className="p-2.5 text-sm text-right">
        <div className="inline">
          <p>&copy; 2023 Designed & Built by Alen Bašić</p>
        </div>
      </footer>
    </>
  );
};

export default LetsConnect;
