import style from "./PreLoader.module.css";

import LoaderLogo from "../../old/pics/navlogowhitebold.svg";

const PreLoader = () => {
  return (
    <div className={style.loaderwrapper}>
      <div className={style.loader}>
        <img src={LoaderLogo} alt="Alen's logo" />
      </div>
    </div>
  );
};
export default PreLoader;
