import { useRef } from "react";
import style from "./Card.module.css";

const Card = (props) => {
  const cardRef = useRef();
  let bounds;

  const rotateToMouse = (e) => {
    bounds = cardRef.current.getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2,
    };
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    cardRef.current.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance) * 2}deg
      )
    `;
  };

  const removeListener = (e) => {
    cardRef.current.style.transform = "";
    cardRef.current.style.background = "";
  };

  return (
    <div className={style.perspective}>
      <div
        ref={cardRef}
        onMouseLeave={removeListener}
        onMouseMove={rotateToMouse}
        className={style.card}
      >
        {props.url && (
          <a
            href={props.url}
            target="_blank"
            rel="noreferrer noopener"
            className="absolute h-full w-full z-10"
          >
            {" "}
          </a>
        )}

        <img
          src={props.img}
          alt={props.title + " image"}
          className="object-cover h-40 w-full rounded-t"
        />

        <div className="px-3">
          <h1 className="font-bold text-xl py-4">{props.title}</h1>
          {props.skills && (
            <div className="flex flex-wrap gap-2">
              {props.skills.map((skill) => (
                <div className="px-3 text-base rounded-full bg-stone-300">
                  {skill}
                </div>
              ))}
            </div>
          )}
          <p className="text-base py-5 px-3 indent-5">{props.text}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
