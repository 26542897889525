import Card from "./Card/Card";

import UlmImg from "../../old/pics/ulm-after.jpg";
import CrowImg from "../../old/pics/crow-after.jpg";
import BnbImg from "../../old/pics/bnb-laptop.jpg";
import XeneonImg from "../../pics/xeneon.jpg";
import VoyagerImg from "../../pics/voyager.jpg";
import VodafoneImg from "../../pics/vodafone.jpg";
import LonewolfImg from "../../pics/lonewolf.jpg";
import foodImg from "../../pics/foodforthought.jpg";

const MyWork = () => {
  return (
    <div className="relative">
      <a name="portfolioAnchor" href="/" className="absolute -top-20">
        {" "}
      </a>
      <h1 className="text-center my-5">My Work</h1>
      <div className="max-w-4xl mx-auto flex justify-center flex-wrap gap-10 px-5 mb-5">
        <Card
          img={VoyagerImg}
          title="Corsair - Voyager"
          skills={["JavaScript", "Alpine.js", "Twig", "Tailwind"]}
          text="A webpage for Corsair's new gaming laptop that I created with tonnes of animations and Corsair's assets."
          url="https://www.corsair.com/ca/en/Categories/Products/Gaming-Laptops/CORSAIR-VOYAGER-Gaming-Notebook/p/CN-9000004-NA"
        />
        <Card
          img={XeneonImg}
          title="Corsair - Xeneon Flex"
          skills={["React", "Twig", "Tailwind"]}
          text="Worked with a team to develop and implement the web page with an interactive flex monitor that was created with Three.js."
          url="https://www.corsair.com/ca/en/Categories/Products/Monitors/CORSAIR-XENEON-FLEX-45WQHD240-Gaming-Monitor/p/CM-9030001-NA"
        />
        <Card
          img={VodafoneImg}
          title="Vodafone - Insurance Claim"
          skills={["JavaScript", "HTML", "CSS"]}
          text="Created a 90+ page application for Vodafone that guides the user from login to insurance claim, including a custom chat bot feature."
        />
        <Card
          img={LonewolfImg}
          title="Lonewolf Technologies"
          skills={["SCSS", "HTML", "YAML", "Web Design", "Git"]}
          text="I was in charge of creating custom branded CMAs for top global brands such as RE/MAX, Sotheby's, Engel & Völkers."
        />
        <Card
          img={foodImg}
          title="Food for Thought"
          skills={["React", "HTML", "CSS", "Firebase"]}
          text="A personal application that I created that is linked to my Firbase backend data. The meals are pulled from the api and the orders are sent back into the data base. Try it out!"
          url="https://react.alenbasic.ca"
        />
        <Card
          img={UlmImg}
          title="Ulm Transport"
          skills={["Photoshop", "Illustrator", "Brand Design"]}
          text="My own branding project with emphasis on creating a brand revolving around being a German transportation company."
        />
        <Card
          img={CrowImg}
          title="Dutchman & The Crow"
          skills={["Photoshop", "Illustrator", "Brand Design"]}
          text="Designed a coffee brand and logo that represents a rich coffee culture with a hint of Dutch influence."
        />
        <Card
          img={BnbImg}
          title="BnB Media"
          skills={["Photoshop", "Illustrator", "Brand Design"]}
          text="Design concept of a landing page for a personal project media company."
        />
      </div>
    </div>
  );
};

export default MyWork;
