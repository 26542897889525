import style from "./NavBar.module.css";

const NavBar = () => {
  function toggleBurger() {
    document.getElementById("myDropdown").classList.toggle(style["show"]);
  }

  window.onclick = function (event) {
    if (!event.target.matches("#dropbtn")) {
      const dropdowns = document.getElementsByClassName(
        style["dropdowncontent"]
      );
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains(style["show"])) {
          openDropdown.classList.remove(style["show"]);
        }
      }
    }
  };

  return (
    <nav className={style.navroot}>
      <span>
        <div className={style.navlogo}>
          <a href="#homeAnchor" />
        </div>
        <span className={style.navbarseperator}></span>
      </span>

      <div className={style.navbarcontent}>
        <div className={style.navbarlink}>
          <a href="#homeAnchor">Home</a>
        </div>

        <div className={style.navbarlink}>
          <a href="#portfolioAnchor">Portfolio</a>
        </div>

        <div className={style.navbarlink}>
          <a href="#contactAnchor">Contact</a>
        </div>
      </div>

      <div className={style.dropdown}>
        <button id="dropbtn" onClick={toggleBurger} className={style.dropbtn}>
          <div className={style.hmbrgrIcon}></div>
          <div className={style.hmbrgrIcon}></div>
          <div className={style.hmbrgrIcon}></div>
        </button>
        <div id="myDropdown" className={style.dropdowncontent}>
          <a href="#homeAnchor">Home</a>
          <a href="#portfolioAnchor">Portfolio</a>
          <a href="#contactAnchor">Contact</a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
