import Headshot from "../../old/pics/a_headshot.jpg";
import style from "./Header.module.css";

const Header = () => {
  return (
    <>
      <a name="homeAnchor" />
      <div className={style.hero}>
        <div className="flex justify-around max-w-4xl mx-auto">
          <div className="self-center px-5">
            <h1>Hello there!</h1>
          </div>
          <div className={style.headshot}>
            <img src={Headshot} alt="Headshot of Alen Basic" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
